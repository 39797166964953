// @import "normalize";

@import "./node_modules/plyr/src/sass/plyr.scss";


@font-face {
	font-family: 'HAL Matex';
	src: url('./data/fonts/HALMatex-Medium.woff2') format('woff2'),
		url('./data/fonts/HALMatex-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HAL Matex';
	src: url('./data/fonts/HALMatex-Medium.woff2') format('woff2'),
		url('./data/fonts/HALMatex-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HAL Matex';
	src: url('./data/fonts/HALMatex-Light.woff2') format('woff2'),
		url('./data/fonts/HALMatex-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HAL Matex';
	src: url('./data/fonts/HALMatex-Light.woff2') format('woff2'),
		url('./data/fonts/HALMatex-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}



*{
	margin: 0;
	padding: 0;

	font-family: HAL Matex, Arial, Helvetica, sans-serif;
	font-weight: 300;
	font-size: 20px;
}

a{
	&:hover,
	&:active,
	&:focus{
		text-decoration: none;
	}
	
	text-decoration: none;
}

strong{
	font-weight: 500;

}

sup{
	font-size: .7em;
}

em{
	font-weight: inherit;
}

body{
	width: 100vw;
	height: 100vh;
}

.map {
	width: 100%;
	height: 100%;
}
.tooltip-inner {
	white-space: nowrap;
}

p{
	margin: 0;
	padding: 0;
}

.page.overlay{
	position: fixed;
	z-index: 999;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100vw;
	max-height: 33.33vh;

	background-color: #EC5D2A;
	box-sizing: border-box;
	padding: 20px;
	overflow: auto;
	overflow-y: scroll;
	display: none;

	&.open{
		display: block;
	}

	p strong:last-of-type{
		// display: block;
		margin-bottom: 10px;
	}

	&.full-page{
		background-color: #0009FF;
		color: #fff;
		height: 100vh;
		max-height: none;
		top: 0;
		// padding-top: 20px;
		z-index: 10000;

		p{
			margin-bottom: 20px;
		}

		a{
			color: #fff;
			text-decoration: underline;
		}
	
		.close-x{
			top: 10px;
		}

		ul{
			li{
				list-style: none;
				margin-bottom: 10px;
				margin-left: 20px;
			}
		}

		.hide-legal{
			display: none;

			h3,
			h4{
				font-weight: 500;
			}
		}

		.toggle-legal,
		.hide-legal{
			padding-bottom: 100px;
		}
	}
}

.button{
	&.top-right{
		cursor: pointer;
		position: fixed;
		z-index: 9999;
		top: 0;
		right: 10px;
		// width: 30vw;
		// max-width: 160px;
		
	}
}

svg{
	text{
		top: 50%;
		left: -50%;
		font-size: 13em;
		fill: white;
	}
}

.close-x{
	position: fixed;
	cursor: pointer;
    right: 20px;
    font-size: 2em;
}



/*plyr*/
:root{

	--plyr-color-main: #000;
	--plyr-tab-focus-color: none;
	--plyr-audio-controls-background: transparent;
	--plyr-audio-control-color: #000;
	--plyr-range-fill-background: #000;
	--plyr-progress-loading-background: #000;
	--plyr-video-progress-buffered-background: #000;
	--plyr-audio-progress-buffered-background: #000;
	--plyr-audio-range-thumb-active-shadow-color : #000;
	--plyr-video-range-track-background: #000;
	--plyr-range-thumb-background: #000;
	--plyr-video-control-background-hover: transparent;
	--plyr-audio-control-color-hover: #000;
	--plyr-audio-control-color: #000;
	--plyr-audio-control-background-hover: transparent;


	// var(, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)))
}

.audio-file{
	margin-left: -15px;
}
.plyr__controls{
	margin: 20px 0 0 0;
	padding: 0;

	.plyr__progress__container{
		order: 2;

		// input[type="range"]{
		// 	display: block;
		// 	height: 20px;
		// 	width: 100%;
		// 	margin: 0;
		// 	padding: 0;
		// 	background: black;
		// 	height: 4px;
		// 	box-shadow: none;
		// 	vertical-align: middle;
			
		// 	appearance: none;
		// 	cursor: pointer;
		// 	border: none;
		// 	background: 0 0;
		// }
	}

	.plyr__time{
		order: 1;
	}
	
}

// .plyr.plyr--audio .plyr__controls .plyr__progress .plyr__progress--played, .plyr.plyr--audio .plyr__controls .plyr__progress .plyr__progress--buffer {
//     color: #000;
//     height: .125rem;
//     background: #000;
//     margin: -.08333rem 0 0;
// }

// .plyr--audio{

// 	.plyr__progress__buffer,
// 	.plyr__progress--played{
// 		color: #000;
// 		height: .125rem;
// 		background: #000;
// 		margin: -.08333rem 0 0;
// 		appearance: none;
// 		border: none;
// 		border-radius: 100px;
// 	}
// }

